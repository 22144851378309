var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('default-template', [_c('v-layout', {
    staticClass: "fill-height justify-center align-center background-content"
  }, [_c('v-responsive', {
    staticClass: "overflow-visible",
    attrs: {
      "width": "100vw",
      "min-height": _vm.$vuetify.breakpoint.mobile ? '83vh' : '50vh'
    }
  }, [_c('v-card', {
    staticClass: "subvisual-pa0",
    attrs: {
      "tile": "",
      "elevation": "0"
    }
  }, [_c('v-card-title', {
    staticClass: "headline d-block pa-0"
  }, [-1 < _vm.$route.path.indexOf('/center/notifications') ? _c('div', {}, [_c('div', {
    staticClass: "sub-visual-container sv2"
  }, [_c('div', {
    staticClass: "sub-visual-wrapper"
  }, [_c('h1', {
    staticClass: "sub-visual--title"
  }, [_vm._v("공지사항")])])])]) : _vm._e(), -1 < _vm.$route.path.indexOf('/center/faqs') ? _c('div', {}, [_c('div', {
    staticClass: "sub-visual-container sv3"
  }, [_c('div', {
    staticClass: "sub-visual-wrapper"
  }, [_c('h1', {
    staticClass: "sub-visual--title"
  }, [_vm._v("자주묻는질문")])])])]) : _vm._e(), -1 < _vm.$route.path.indexOf('/board/question') ? _c('div', {}, [_c('div', {
    staticClass: "sub-visual-container sv4"
  }, [_c('div', {
    staticClass: "sub-visual-wrapper"
  }, [_c('h1', {
    staticClass: "sub-visual--title"
  }, [_vm._v("문의하기")])])])]) : _vm._e()])], 1), _c('v-responsive', {
    staticClass: "justify-center align-center mx-auto sub-container__new",
    attrs: {
      "width": "100vw",
      "max-width": "1230px"
    }
  }, [_vm._t("default")], 2)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }